import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import slide1 from '../../../static/assets/slider/mobile/slide-1.png';
import slide2 from '../../../static/assets/slider/mobile/slide-2.png';
import slide3 from '../../../static/assets/slider/mobile/slide-3.png';
import slide4 from '../../../static/assets/slider/mobile/slide-4.png';
import slide5 from '../../../static/assets/slider/mobile/slide-5.png';
import slide6 from '../../../static/assets/slider/mobile/slide-6.png';
import slide7 from '../../../static/assets/slider/mobile/slide-7.png';
import slide8 from '../../../static/assets/slider/mobile/slide-8.png';

const MobilePage = () => (
  <Layout>
    <SEO title="EasyBusy Mobile: Make achieving easier!" />

    <div className={'call-to-action'}>
      <div className={'container'}>
        <div className={'call-to-action__content'}>
          <h2>Download mobile app</h2>
          <p>
            Install our app on you mobile device and sync all your data with your
            other mobile devices or with multiple computers.
          </p>

          <div className={'buttons-list'}>
            <div className={'button'}>
              <a
                href="https://apps.apple.com/us/app/easybusy/id1561908615"
                target={'_blank'}
                rel="noreferrer"
              >
                <i className="fab fa-apple" />
                Apple iOS
              </a>
            </div>

            <div className={'button'}>
              <a href="#" target={'_blank'} rel="noreferrer">
                <i className="fab fa-android" />
                Coming soon
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={'page-header home'}>
      <img alt={'slide1'} src={slide1} />
      <img alt={'slide2'} src={slide2} />
      <img alt={'slide3'} src={slide3} />
      <img alt={'slide4'} src={slide4} />
      <img alt={'slide5'} src={slide5} />
      <img alt={'slide6'} src={slide6} />
      <img alt={'slide7'} src={slide7} />
      <img alt={'slide8'} src={slide8} />
    </div>
  </Layout>
);

export default MobilePage;
